import React, { useState } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { LoginModal } from '../modules/login/login-modal';
interface IOwnProps extends RouteProps {
  hasAnyAuthorities?: string[];
  location?: any;
}

const Auth = new AuthService();

export interface IPrivateRouteProps extends IOwnProps { }

export const PrivateRoute = ({
  component: Component,
  hasAnyAuthorities = [],
  ...rest
}: IPrivateRouteProps) => {

  const[isOpen, setIsOpen] = useState<boolean>(true);

  const checkAuthorities = (props: IPrivateRouteProps) =>
    Auth.hasAnyAuthorities(hasAnyAuthorities) ? (
      (<Route>
        <Route component={Component} />
      </Route>)
    ) : (
        <div className="insufficient-authority">
          <div className="alert alert-danger">
            <p>You are not authorized to access this page.</p>
          </div>
        </div>
      );

  const renderRedirect = (props: IPrivateRouteProps) => {
    return Auth.loggedIn() ? (
      checkAuthorities(props)
    ) : (
        
        <LoginModal isOpen={true} close={ () => setIsOpen(false) }/>
      );
    // }
  };

  if (!Component) throw new Error(`A component needs to be specified for private route for path ${(rest as any).path}`);

  return <Route {...rest} render={renderRedirect} />;
};

