import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Input, Button } from "reactstrap";
import { findAllNumocl } from "../../api/user.api";
import Select from 'react-select';
import { IUserDTO } from "../../model/User.model";


interface IModalModifyUserProps {
    isOpen: boolean;
    user: any;
    onClickClose: () => void;
    handleSelect: (suggestion: any) => void;
}

export const ModalModifyUser = (props: IModalModifyUserProps) => {

    const [user, setUser] = useState<any>();
    const [login, setLogin] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    useEffect(() => {
        setUser(props.user);
        setLogin(props.user ? props.user.login : "");
        setEmail(props.user ? props.user.email : "");
        setFirstName(props.user ? props.user.firstName : "");
        setLastName(props.user ? props.user.lastName : "");
    }, [props.user])

    const handleClick = () => {

        const toSave : any = {
            id:  user.id,
            login,
            firstName,
            lastName,
            email

        };

        props.handleSelect(toSave);

    }


    return (
        <Modal isOpen={props.isOpen} className="setWidth" onClickClose={props.onClickClose}>
            <ModalHeader toggle={props.onClickClose}>Modifier un utilisateur</ModalHeader>
            <ModalBody>
                {
                    user ?
                        <div>
                            <Row>
                                <Col md={4}>
                                    <span>Login</span>
                                </Col>
                                <Col md={8}>
                                    <Input disabled value={login} onChange={(e: any) => setLogin(e.target.value)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <span>Prénom</span>
                                </Col>
                                <Col md={8}>
                                    <Input value={firstName} onChange={(e: any) => setFirstName(e.target.value)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <span>Nom</span>
                                </Col>
                                <Col md={8}>
                                    <Input value={lastName} onChange={(e: any) => setLastName(e.target.value)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <span>Email</span>
                                </Col>
                                <Col md={8}>
                                    <Input value={email} onChange={(e: any) => setEmail(e.target.value)} />
                                </Col>
                            </Row>
                        </div>
                        : null}
                <div style={{ textAlign: "end", marginTop: "2%" }}>
                    <Button onClick={handleClick} >Valider</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}