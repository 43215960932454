import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Home } from './modules/Home';
import { PrivateRoute } from './services/private-route';
import { Router } from 'react-router-dom';
import AuthService from './services/AuthService';
import { createBrowserHistory } from 'history';
import { Header } from './modules/header/header';
import { CreateUser } from './modules/newUser/createUser';
import { GestionUsers } from './modules/gestionUsers/gestionUsers';
import { ChangePassword } from './modules/changePassword/changePassword';


const Auth = new AuthService();
const history = createBrowserHistory();




const App: React.FC = () => {

  const handleClickLogout = () => {
    Auth.logout();
    history.replace("/");

  }

  const handleClickPasswordChange = () => {
    history.replace("/password");
  }

  return (
    <Router history={history} >
       <Header loggedIn={true} clickLogin={() => ""} logout={handleClickLogout} clickPasswordChange={handleClickPasswordChange}/>
    <div className="App">
        
      <PrivateRoute path="/" component={Home} hasAnyAuthorities={['ROLE_ADMIN']}/>
      <PrivateRoute path="/createUser" component={CreateUser} hasAnyAuthorities={['ROLE_ADMIN']}/>
      <PrivateRoute path="/gestionUsers" component={GestionUsers} hasAnyAuthorities={['ROLE_ADMIN']}/>
      <PrivateRoute path="/password" component={ChangePassword} hasAnyAuthorities={['ROLE_ADMIN','ROLE_USER']}/>
    </div>
    </Router>
  );
}

export default App;
