import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { findAllNumocl } from "../../api/user.api";
import Select from 'react-select';


interface IUserSearchModalProps {
    isOpen: boolean;
    onClickClose: () => void;
    handleSelect: (suggestion: any) => void;
}

export const CoocSearchModal = (props: IUserSearchModalProps) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [numocls, setNumocls] = useState<String[]>([]);

    const handleChangeAutocomplete = (value: any) => {
        setIsLoading(true);
        props.handleSelect(value.value);
    }

    useEffect(() => {
        findAllNumocl().then(res => {
            const values = res.map((r: String) => {return { value: r, label : r}})
            setNumocls(values);
        })
    }, [])

    return (
        <Modal isOpen={props.isOpen} className="setWidth" onClickClose={props.onClickClose}>
            <ModalHeader toggle={props.onClickClose}>Ajouter un NUMOCLS à l'utilisateur</ModalHeader>
            <ModalBody>
                <Select options={numocls} onChange={handleChangeAutocomplete} />
                {/* {isLoading ? <div className="alignCenter"><CircularProgress /></div> : null } */}
            </ModalBody>
        </Modal>
    )
}