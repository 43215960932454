import React, { useState, useEffect } from "react";
import { Row, Col, Table, Badge, Button } from "reactstrap";
import { getAllUsersWithAuthorities, setCoocToUser, removeCoocFromUser, setAuthorityToUser, removeAuthorityToUser, modifyUser, deleteUser } from "../../api/user.api";
import { IUserDTO } from "../../model/User.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Cooc } from "../../model/Cooc.model";
import { faTimesCircle, faPlus, faPen } from "@fortawesome/free-solid-svg-icons";
import { CoocSearchModal } from "./modalAddCooc";
import { AuthoritySearchModal } from "./modalAddAuthority";
import { ModalModifyUser } from "./modalModifyUser";


export const GestionUsers = () => {

    const [users, setUsers] = useState<IUserDTO[] | undefined>(undefined);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpenUser, setIsOpenUser] = useState<boolean>(false);
    const [isOpenAuthority, setIsOpenAuthority] = useState<boolean>(false);
    const [idUser, setIdUser] = useState<number | undefined>(undefined);
    const [userToModify, setUserToModify] = useState<IUserDTO | undefined>(undefined);

    useEffect(() => {
        getAllUsersWithAuthorities().then(res => {
            setUsers(res);
        })
    }, [])

    const handleAuthDelete = (userId: number, auth: string) => {
        setIdUser(userId);
        removeAuthorityToUser(auth, userId).then(res => {
            if (res !== undefined) {
                const index = users?.findIndex(cs => cs.id === res.id);
                if (index !== undefined && index !== -1) {
                    const newUsers = users
                    const spliced = newUsers?.splice(index, 1, res);
                    setUsers(newUsers);
                    setIsOpen(false);
                    setIdUser(undefined);
                }
            }
            setIsOpen(false);
            setIdUser(undefined);
        })
    }

    const handleCoocDelete = (userId: number, coocId: number) => {
        setIdUser(userId);
        removeCoocFromUser(coocId, userId).then(res => {
            if (res !== undefined) {
                const index = users?.findIndex(cs => cs.id === res.id);
                if (index !== undefined && index !== -1) {
                    const newUsers = users
                    const spliced = newUsers?.splice(index, 1, res);
                    setUsers(newUsers);
                    setIsOpen(false);
                    setIdUser(undefined);
                }
            }
            setIsOpen(false);
            setIdUser(undefined);
        })


    }
    const handleClickAddCooc = (userId: number) => {
        setIdUser(userId);
        setIsOpen(true);

    }

    const handleClickAddAuthority = (userId: number) => {
        setIdUser(userId);
        setIsOpenAuthority(true);

    }
    const handleSelect = (cooc: any) => {
        if (idUser) {
            setCoocToUser(cooc, idUser).then(res => {
                if (res !== undefined) {
                    const index = users?.findIndex(cs => cs.id === res.id);
                    if (index !== undefined && index !== -1) {
                        const newUsers = users
                        const spliced = newUsers?.splice(index, 1, res);
                        setUsers(newUsers);
                        setIsOpen(false);
                        setIdUser(undefined);
                    }
                }
                setIsOpen(false);
                setIdUser(undefined);
            })
        }
    }

    const handleClickModifyUser = async (user: any) => {
        await setUserToModify(user);
        setIsOpenUser(true);
    }

    const handleSelectAuthority = (authority: any) => {
        if (idUser) {
            setAuthorityToUser(authority, idUser).then((res: any) => {
                if (res !== undefined) {
                    const index = users?.findIndex(cs => cs.id === res.id);
                    if (index !== undefined && index !== -1) {
                        const newUsers = users
                        const spliced = newUsers?.splice(index, 1, res);
                        setUsers(newUsers);
                        setIsOpenAuthority(false);
                        setIdUser(undefined);
                    }
                }
                setIsOpenAuthority(false);
                setIdUser(undefined);
            })
        }
    }

    const handleClickDeleteUser = (id : number) => {
        deleteUser(id).then( res => {
            window.location.reload();
        })
    }

    const handleValidateModifyUser = (newUser : IUserDTO) => {
        modifyUser(newUser).then(res => {
            if (res !== undefined) {
                const index = users?.findIndex(cs => cs.id === res.id);
                if (index !== undefined && index !== -1) {
                    const newUsers = users
                    const spliced = newUsers?.splice(index, 1, res);
                    setUsers(newUsers);
                    setIsOpenAuthority(false);
                    setIdUser(undefined);
                }
            }
            setIsOpenUser(false);
            setUserToModify(undefined);
        })
    }

    return (
        <div>
            <ModalModifyUser user={userToModify} isOpen={isOpenUser} handleSelect={handleValidateModifyUser} onClickClose={() => setIsOpenUser(false)} />
            <CoocSearchModal isOpen={isOpen} handleSelect={handleSelect} onClickClose={() => setIsOpen(false)} />
            <AuthoritySearchModal isOpen={isOpenAuthority} handleSelect={handleSelectAuthority} onClickClose={() => setIsOpenAuthority(false)} />
            <Row>
                <Col md={2} />
                <Col md={8}>
                    <h1>Gestion des utilisateurs</h1>
                    <Table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Login</th>
                                <th>User</th>
                                <th>Email</th>
                                <th>Authorities</th>
                                <th>Numocls</th>
                                <th>Gestion</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users ? users.map((c: IUserDTO) => {
                                return (
                                    <tr key={c.id}>
                                        <th scope="row">{c.id}</th>
                                        <th>{c.login}</th>
                                        <th>{c.firstName} {c.lastName}</th>
                                        <th>{c.email}</th>
                                        <th>{c.authorities ? c.authorities.map((auth: any, i: number) => {
                                            return <div>
                                                <Badge key={i} className="BadgeStyle" color="primary">
                                                    <span className="margedRight">{auth.name} </span>
                                                    {auth.name !== "ROLE_ADMIN" && auth.name !== "ROLE_USER" ?
                                                        <FontAwesomeIcon onClick={() => handleAuthDelete(c.id, auth.name)}
                                                            className="cursorPointer" icon={faTimesCircle} /> : null}
                                                </Badge>

                                            </div>
                                        }) : null}
                                            <Button onClick={() => handleClickAddAuthority(c.id)}><FontAwesomeIcon icon={faPlus} /></Button>
                                        </th>

                                        <th>{c.coocs ? c.coocs.map((cooc: Cooc) => {
                                            return <div><Badge key={cooc.id} className="BadgeStyle" color="primary">
                                                <span className="margedRight">{cooc.name} </span>
                                                <FontAwesomeIcon onClick={() => handleCoocDelete(c.id, cooc.id)} className="cursorPointer" icon={faTimesCircle} />
                                            </Badge></div>
                                        }) : null}

                                            <Button onClick={() => handleClickAddCooc(c.id)}><FontAwesomeIcon icon={faPlus} /></Button></th>
                                        <th>

                                            <Button onClick={() => handleClickModifyUser(c)}><span style={{display: "flex"}}><FontAwesomeIcon icon={faPen} style={{ marginRight: "3px"}}/> Modifier</span></Button>
                                            { c.authorities.findIndex(au => au.name === "ROLE_ADMIN") === -1 ? <Button color="danger" onClick={() => handleClickDeleteUser(c.id)}><span style={{display: "flex"}}><FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: "3px"}}/> Supprimer</span></Button> : null }
                                        </th>
                                    </tr>
                                )
                            }) : null}
                        </tbody>
                    </Table>
                </Col>
            </Row >

        </div >
    )
}