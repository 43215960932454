import React, { ChangeEvent, useState } from 'react';
import { FormControl, FormGroup, Button } from "react-bootstrap";
import { changePassword } from '../../api/user.api';
import { IPasswordChangeDTO } from '../../model/passwordChangeDTO.model';
import AuthService from '../../services/AuthService';

export const ChangePassword = () => {
    const Auth = new AuthService();
    const [password, setPassword] = useState<string>('');
    const [oldPassword, setOldPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [isChanging, setIsChanging] = useState<boolean>(false);
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');

    const validateForm = () => {
        return (
            oldPassword.length > 0 &&
            password.length > 0 &&
            password === confirmPassword
        );

    }
    const updatePassword = (changepassword: IPasswordChangeDTO) => {
        setCurrentPassword(oldPassword);
        setNewPassword(password);

        changePassword(changepassword).then((response) => {
            if (response == undefined) {
                alert('Ancien mot de passe incorrect')
                setOldPassword("");
            } else {
                alert('Mot de passe changé avec succès')
                Auth.logout();
                window.location.replace('/');
            }
        })

    }

    const handleSubmit = () => {
        var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
        if (password.match(passw)) {
            const changeuP: IPasswordChangeDTO = {
                currentPassword: oldPassword,
                newPassword: password

            };
            updatePassword(changeuP);
            return true;
        }
        else {
            alert('Mot de passe doit être composé de 6 à 20 caractères contenant au moins un chiffre , une majuscule et une minuscule')
            return false;
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setOldPassword(e.target.value);
    }

    const onConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
        setIsChanging(validate('confirmPassword', e.target.value))

    }

    const onNewPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        setIsChanging(validate('password', e.target.value));

    }

    const validate = (changed: string, pw: string) => {
        if (changed === 'password') {
            return password === pw;
        } else if (changed === 'confirmPassword') {
            return confirmPassword === pw;
        }
        return false;
    }

    return (
        <div className="ChangePassword">
            <h1>Changer mot de passe</h1>
            {/* <form onSubmit={this.handleChangeClick}> */}
            <form className="login-form-group gf-form-group">
                <FormGroup controlId="oldPassword">
                    <p>Ancien mot de passe</p>
                    <FormControl
                        type="password"
                        onChange={handleChange}
                        value={oldPassword}
                    />
                </FormGroup>
                <hr />
                <FormGroup controlId="onNewPasswordChange">
                    <p>Nouveau mot de passe</p>
                    <FormControl
                        type="password"
                        name="onNewPasswordChange"
                        className="gf-form-input login-form-input"
                        required
                        ng-model="command.password"
                        value={password}
                        onChange={onNewPasswordChange}
                    />
                </FormGroup>
                <FormGroup controlId="confirmPassword">
                    <p>Confirmez mot de passe</p>
                    <FormControl
                        type="password"
                        name="confirmPassword"
                        className="gf-form-input login-form-input"
                        required
                        ng-model="command.confirmPassword"
                        onChange={onConfirmPasswordChange}
                        value={confirmPassword}
                    />
                </FormGroup>
                <Button
                    block
                    className={`btn btn-large p-x-2 ${isChanging ? 'btn-primary' : 'btn-inverse'}`}
                    onClick={handleSubmit}
                    disabled={!validateForm}>Change Password</Button>
            </form>
        </div>
    );

}