import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { findAllNumocl, findAllAuthorites } from "../../api/user.api";
import Select from 'react-select';


interface IUserSearchModalProps {
    isOpen: boolean;
    onClickClose: () => void;
    handleSelect: (suggestion: any) => void;
}

export const AuthoritySearchModal = (props: IUserSearchModalProps) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [authorities, setAuthorities] = useState<String[]>([]);

    const handleChangeAutocomplete = (value: any) => {
        setIsLoading(true);
        props.handleSelect(value.value);
    }

    useEffect(() => {
        findAllAuthorites().then((res: any) => {
            const values = res.map((r: String) => {return { value: r, label : r}})
            setAuthorities(values);
        })
    }, [])

    return (
        <Modal isOpen={props.isOpen} className="setWidth" onClickClose={props.onClickClose}>
            <ModalHeader toggle={props.onClickClose}>Ajouter une authorisation à l'utilisateur</ModalHeader>
            <ModalBody>
                <Select options={authorities} onChange={handleChangeAutocomplete} />
                {/* {isLoading ? <div className="alignCenter"><CircularProgress /></div> : null } */}
            </ModalBody>
        </Modal>
    )
}