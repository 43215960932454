import React, { ChangeEvent, useState } from 'react';
import { FormControl, FormGroup, Button, Row, Col } from "react-bootstrap";
import { INewUserDTO } from '../../model/newUserDTO.model';
import { createUser } from '../../api/user.api';

export const CreateUser = () => {
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [login, setLogin] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const validateForm = () => {
        return (
            login.length > 0 &&
            password.length > 0
        );

    }
    const updatePassword = (user: INewUserDTO) => {
        setLogin(login);
        setPassword(password);
        createUser(user).then((response) => {
            if (response === undefined) {
                alert('Erreur dans la création du nouvel utilisateur')
            } else {
                alert('Votre nouveau compte a été créé avec succès')
                setLogin("");
                setFirstName("");
                setLastName("");
                setEmail("");
                setPassword("");
            }

        })

    }

    const handleSubmit = () => {
        var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
        if(password === confirmPassword) {
        if (password.match(passw)) {
            const changeuP: INewUserDTO = {
                login: login,
                password: password,
                firstName: firstName,
                lastName: lastName,
                email: email

            };
            updatePassword(changeuP);
            return true;
        }
        else {
            alert('Mot de passe doit être composé de 6 à 20 caractères contenant au moins un chiffre , une majuscule et une minuscule')
            return false;
        }
    }
    else {
        alert('Les mots de passe ne correspondent pas.')
        return false;
    }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "login") {
            setLogin(e.target.value);
        }
        if (e.target.name === "firstName") {
            setFirstName(e.target.value);
        }
        if (e.target.name === "lastName") {
            setLastName(e.target.value);
        }
        if (e.target.name === "email") {
            setEmail(e.target.value);
        }
        if(e.target.name === "password") {
            setPassword(e.target.value);
        }
        if(e.target.name === "confirmPassword") {
            setConfirmPassword(e.target.value);
        }

    }


    return (
        <Row>
            <Col md={2} />
            <Col md={8}>
                <div className="ChangePassword">
                    <h1>Création de compte</h1>
                    {/* <form onSubmit={this.handleChangeClick}> */}
                    <form className="login-form-group gf-form-group">
                        <FormGroup controlId="login">
                            <p>Login</p>
                            <FormControl
                                type="text"
                                name="login"
                                onChange={handleChange}
                                value={login}
                            />
                        </FormGroup>

                        <FormGroup controlId="firstName">
                            <p>First Name</p>
                            <FormControl
                                type="text"
                                name="firstName"
                                onChange={handleChange}
                                value={firstName}
                            />
                        </FormGroup>

                        <FormGroup controlId="lastName">
                            <p>Last Name</p>
                            <FormControl
                                type="text"
                                name="lastName"
                                onChange={handleChange}
                                value={lastName}
                            />
                        </FormGroup>

                        <FormGroup controlId="email">
                            <p>Email</p>
                            <FormControl
                                type="text"
                                name="email"
                                onChange={handleChange}
                                value={email}
                            />
                        </FormGroup>
                        <hr />
                        <FormGroup controlId="password">
                            <p>Mot de passe</p>
                            <FormControl
                                type="password"
                                name="password"
                                className="gf-form-input login-form-input"
                                required
                                ng-model="command.password"
                                value={password}
                                onChange={handleChange}
                            />
                        </FormGroup>
                        <FormGroup controlId="password">
                            <p>Confirmer mot de passe</p>
                            <FormControl
                                type="password"
                                name="confirmPassword"
                                className="gf-form-input login-form-input"
                                required
                                ng-model="command.password"
                                value={confirmPassword}
                                onChange={handleChange}
                            />
                        </FormGroup>
                        <Button
                            block
                            onClick={handleSubmit}
                            disabled={!validateForm}>Valider</Button>
                    </form>
                </div>
            </Col>
            <Col md={2} />
        </Row>
    );

}