import React, { useState, useEffect } from 'react';
import './header.css'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';


const logo = require('../../groupe-seenergi.png');



interface IHeaderProps {
    loggedIn : boolean;
    logout: () => void;
    clickLogin: () => void;
    clickPasswordChange: () => void;
}

export const Header = (props: IHeaderProps) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        const theme = localStorage.getItem('theme')
        setIsChecked(theme === "dark" ? true: false);
    },[])

    return (
        <div className="header">
            <Navbar expand="md" className="shadowBottom">
                <NavbarBrand href="/"><img src={logo} width="70px" alt="logo" /></NavbarBrand>
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="mr-auto" navbar>

                    </Nav>


                   
                    {/* {props.isAdmin ? <div className="margedLeft cursorPointer iconColor" onClick={props.handleClickAdmin}><FontAwesomeIcon icon={faUserCog} /></div> : null} */}
                    <Nav>
                        {
                            props.loggedIn ?
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        Options
                            </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem onClick={() => props.clickPasswordChange()}>
                                            Mot de passe
                                </DropdownItem>
                                        
                                       
                                        <DropdownItem divider />
                                        <DropdownItem onClick={() => props.logout()}>
                                            Logout
                                </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown> :
                                <NavItem>
                                    <NavLink href="#" onClick={props.clickLogin}>Login</NavLink>
                                </NavItem>
                        }
                    </Nav>
                </Collapse>

            </Navbar>
        </div>
    );
}